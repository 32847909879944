.container-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.images {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: 10px;
}

/* * {
  margin: 0;
  box-sizing: border-box;
}
.title {
  color: white;
}

.img-container {
  margin: 10px;
  width: 50px;
  height: 50px;
}

.container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slide-panel {
  display: flex;
  align-items: center;
  width: 25em;
  height: 25em;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.prev,
.next {
  color: white;
  cursor: pointer;
  font-size: 40px;
}
.prev:hover,
.next:hover {
  transform: scale(1.25);
}

.img-gallery {
  scroll-snap-align: start;
  min-width: 100%;
  border-radius: 0.2em;
}

::-webkit-scrollbar {
  display: none;
} */

@media only screen and (max-width: 768px) {
  .images {
    width: 150px;
    height: 150px;
    margin: 15px;
  }
}
