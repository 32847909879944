.title {
  font-family: 'IBM Plex Mono';
  color: white;
  font-size: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
header {
  font-family: 'IBM Plex Mono';
  font-size: 30px;
  color: white;
  letter-spacing: 2px;
  line-height: 60px;
  height: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-image: url(7_Raíces_Mezcal4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.9;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }
  header {
    font-family: 'IBM Plex Mono';
    font-size: 20px;
    line-height: 50px;
    height: 400px;
    background-attachment: local;
  }
}
