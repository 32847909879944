nav {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0;
  line-height: 28px;
  position: sticky;
  top: 0;
  padding-top: 10px;
  z-index: 2;
}
nav ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}
nav ul li {
  list-style: none;
  margin: 10px 20px 0px 0px; /* top, right, bottom, left */
}
nav ul li a {
  display: block;
  font-family: 'IBM Plex Mono';
  font-size: 16px;
  color: white;
  padding: 5px;
}

.listItems:hover {
  color: gray;
  padding: 5px 5px;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  width: 2.25rem;
  height: 2rem;
}

.listItems {
  list-style: none;
  font-family: 'IBM Plex Mono';
  font-size: 16px;
}

.logo {
  height: 70px;
  width: 70px;
  margin-left: 20px;
  border-radius: 50%;
}
/*  MOBILE */

@media only screen and (max-width: 768px) {
  nav .menu {
    display: flex;
  }
  nav {
    margin-top: 5px;
    background-color: black;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    margin-bottom: 60px;
  }
  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav ul.open {
    display: flex;
    margin-top: 50px;
  }
  nav ul li {
    width: 100%;
    text-align: center;
  }
  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
  .listItems {
    font-size: 12px;
    padding: 3px;
    display: flex;
    justify-content: center;
  }

  .logo {
    height: 50px;
    width: 50px;
  }
}
