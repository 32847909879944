footer {
  font-family: 'IBM Plex Mono';
  font-size: 14px;
  color: white;
  letter-spacing: 0;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-image: url(../../images/background-min.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 200px;
}
.by7raices {
  font-size: 12px;
}
.footerData {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.socialmedia {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialmedia img {
  width: 30px;
  margin: 10px;
  transition: width 0.3s, height 0.3s, transform 0.5s;
  color: white;
}
.socialmedia img:hover {
  background-color: gray;
  width: 30px;
  height: 30px;
  transform: rotate(40deg);
}
.btn-circle {
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

@media only screen and (max-width: 768px) {
  .socialmedia img {
    width: 25px;
    margin: 5px;
  }
  footer {
    height: 100px;
  }
}
