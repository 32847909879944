.text-our-mezcal {
  color: white;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 10;
  line-height: 40px;
  letter-spacing: 5px;
  width: 70%;
  margin-top: 50px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.title-our-mezcal {
  color: white;
  font-size: 30px;
  margin-top: 150px;
  margin-bottom: 50px;
}
.container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .text-our-mezcal {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 3px;
    margin-top: 30px;
  }
  .title-our-mezcal {
    font-size: 22px;
    margin-top: 100px;
    margin-bottom: 50px;
  }
}
