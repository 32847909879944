.bg {
  background-image: url(../../images/datasheet-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.data-title {
  color: white;
  font-size: 40px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.data-text {
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-size: 20px;
  font-weight: lighter;
  letter-spacing: 2px;
  line-height: 30px;
  margin: 10px 80px;
}
.data-flavour {
  color: white;
  font-size: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.container-data-flavour {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.b-img {
  width: auto;
  height: 300px;
  justify-content: center;
  margin: 10px;
  margin-top: 30px;
}
.data-img {
  width: auto;
  height: 400px;
  justify-content: center;
  margin: 10px;
}
.container-data {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .data-title {
    font-size: 30px;
  }

  .container-data {
    display: flex;
    flex-direction: column;
  }
  .data-text {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .b-img {
    width: auto;
    height: 200px;
  }
}
